// If you don't want to use TypeScript you can delete this file!
import React from "react"
import { PageProps, Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Refs = () => (
  <Layout>
    <SEO title="My References" />
    <p>This website would not be possible without a lot of help from many OpenSource developers and creators.
       <br/>
      Here, I attempt to acknowledge this help to the best of my capacities.
      However, if you feel I forgot to mention someone, please <Link to="/contact"> get in touch!</Link>
    </p>
    <ul>
      <li>The framework used to construct this website was <Link to="https://www.gatsbyjs.com">Gatsby</Link></li>
      <li>Much of the CSS used was taken from <Link to="https://github.com/jooplaan/gatsby-london-night-and-day"> London Night and Day </Link></li>
      <li>Homepage Coral Reef Photo by <a href="https://unsplash.com/@silasbaisch">Silas Baisch</a> on <a href="https://unsplash.com/">Unsplash</a></li>
    </ul>

    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default Refs